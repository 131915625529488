const SocialProof = () => {
  return (
    <section className="py-16 bg-black">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-white">
          Trusted By Business Owners
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-gray-800 p-6 rounded-lg">
            <p className="text-gray-50 mb-4">
              "This tool saved me hours of work and helped me understand my
              business finances better than ever."
            </p>
            <div className="flex items-center">
              <div className="ml-3">
                <p className="font-semibold text-white">Sarah Johnson</p>
                <p className="text-sm text-gray-400">Retail Store Owner</p>
              </div>
            </div>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg">
            <p className="text-gray-50 mb-4">
              "The cash flow forecasting has been invaluable for planning our
              growth strategy."
            </p>
            <div className="flex items-center">
              <div className="ml-3">
                <p className="font-semibold text-white">Mark Thompson</p>
                <p className="text-sm text-gray-400">Tech Startup Founder</p>
              </div>
            </div>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg">
            <p className="text-gray-50 mb-4">
              "Finally, a financial tool that speaks my language. Simple yet
              powerful."
            </p>
            <div className="flex items-center">
              <div className="ml-3">
                <p className="font-semibold text-white">Emma Davis</p>
                <p className="text-sm text-gray-400">Freelance Consultant</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialProof;

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";

const ProfitLossView = ({ costs, income }) => {
  const calculateYearlyTotal = (entries) => {
    return entries.reduce((total, entry) => {
      return (
        total + entry.amounts.reduce((sum, amount) => sum + (amount || 0), 0)
      );
    }, 0);
  };

  const calculateCategoryTotal = (entries, category) => {
    return entries
      .filter((entry) => entry.category === category)
      .reduce((total, entry) => {
        return (
          total + entry.amounts.reduce((sum, amount) => sum + (amount || 0), 0)
        );
      }, 0);
  };

  const totalSales = calculateYearlyTotal(income);
  const costOfSales = calculateCategoryTotal(costs, "costOfSales");
  const administrativeCosts = calculateCategoryTotal(costs, "administrative");
  const grossProfit = totalSales - costOfSales;
  const operatingProfit = grossProfit - administrativeCosts;

  const formatNumber = (amount) => {
    return Math.round(amount).toLocaleString("en-GB");
  };

  // Function to download Excel file
  const downloadProfitLoss = () => {
    const workbook = XLSX.utils.book_new();

    // Turnover Sheet
    const turnoverSheet = [
      ["Category", "Amount (£)"],
      ["Sales", totalSales],
      ["Total Turnover", totalSales],
    ];
    const turnoverWS = XLSX.utils.aoa_to_sheet(turnoverSheet);
    XLSX.utils.book_append_sheet(workbook, turnoverWS, "Turnover");

    // Cost of Sales Sheet
    const costOfSalesSheet = [
      ["Category", "Amount (£)"],
      ["Cost of Goods Sold", costOfSales],
      ["Total Cost of Sales", costOfSales],
    ];
    const costOfSalesWS = XLSX.utils.aoa_to_sheet(costOfSalesSheet);
    XLSX.utils.book_append_sheet(workbook, costOfSalesWS, "Cost of Sales");

    // Administrative Costs Sheet
    const administrativeSheet = [["Supplier", "Amount (£)"]];
    costs
      .filter((cost) => cost.category === "administrative")
      .forEach((cost) => {
        const total = cost.amounts.reduce((sum, amount) => sum + (amount || 0), 0);
        administrativeSheet.push([cost.supplierName || "Unnamed Cost", total]);
      });
    administrativeSheet.push(["Total Administrative Costs", administrativeCosts]);
    const administrativeWS = XLSX.utils.aoa_to_sheet(administrativeSheet);
    XLSX.utils.book_append_sheet(workbook, administrativeWS, "Administrative Costs");

    // Summary Sheet
    const summarySheet = [
      ["Category", "Amount (£)"],
      ["Gross Profit", grossProfit],
      ["Operating Profit", operatingProfit],
      ["Profit Before Tax", operatingProfit],
      ["Profit After Tax", operatingProfit],
    ];
    const summaryWS = XLSX.utils.aoa_to_sheet(summarySheet);
    XLSX.utils.book_append_sheet(workbook, summaryWS, "Summary");

    // Download the file
    XLSX.writeFile(workbook, "Profit_Loss_Report.xlsx");
  };

  return (
    <div className="max-w-6xl mx-auto bg-white p-6 text-left shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-4 text-center">Profit and Loss</h1>
      <div className="grid grid-cols-2">
        <div></div>
        <h2 className="text-base text-right">£</h2>
      </div>
      <div className="space-y-4">
        {/* Turnover Section */}
        <section>
          <h2 className="text-lg font-semibold border-b pb-1">Turnover</h2>
          <div className="grid grid-cols-2 py-1">
            <span className="text-sm">Sales</span>
            <span className="text-right text-sm">
              {formatNumber(totalSales)}
            </span>
          </div>
          <div className="grid grid-cols-2 py-1 font-bold border-t">
            <span className="text-sm">Total Turnover</span>
            <span className="text-right text-sm">
              {formatNumber(totalSales)}
            </span>
          </div>
        </section>

        {/* Cost of Sales Section */}
        <section>
          <h2 className="text-lg font-semibold border-b pb-1">Cost of Sales</h2>
          <div className="grid grid-cols-2 py-1">
            <span className="text-sm">Cost of Goods Sold</span>
            <span className="text-right text-sm">
              {formatNumber(costOfSales)}
            </span>
          </div>
          <div className="grid grid-cols-2 py-1 font-bold border-t">
            <span className="text-sm">Total Cost of Sales</span>
            <span className="text-right text-sm">
              {formatNumber(costOfSales)}
            </span>
          </div>
        </section>

        {/* Gross Profit */}
        <section>
          <div className="grid grid-cols-2 py-1 font-bold border-t border-b">
            <span className="text-sm">Gross Profit</span>
            <span className="text-right text-sm">
              {formatNumber(grossProfit)}
            </span>
          </div>
        </section>

        {/* Administrative Costs Section */}
        <section>
          <h2 className="text-lg font-semibold border-b pb-1">
            Administrative Costs
          </h2>
          {costs
            .filter((cost) => cost.category === "administrative")
            .map((cost, index) => (
              <div key={index} className="grid grid-cols-2 py-1">
                <span className="text-sm">
                  {cost.supplierName || "Unnamed Cost"}
                </span>
                <span className="text-right text-sm">
                  {formatNumber(
                    cost.amounts.reduce((sum, amount) => sum + (amount || 0), 0)
                  )}
                </span>
              </div>
            ))}
          <div className="grid grid-cols-2 py-1 font-bold border-t">
            <span className="text-sm">Total Administrative Costs</span>
            <span className="text-right text-sm">
              {formatNumber(administrativeCosts)}
            </span>
          </div>
        </section>

        {/* Operating Profit */}
        <section>
          <div className="grid grid-cols-2 py-1 font-bold border-t border-b">
            <span className="text-sm">Operating Profit</span>
            <span className="text-right text-sm">
              {formatNumber(operatingProfit)}
            </span>
          </div>
        </section>

        {/* Profit before and after Tax */}
        <section>
          <div className="grid grid-cols-2 py-1 font-bold border-b">
            <span className="text-sm">
              Profit on Ordinary Activities Before Taxation
            </span>
            <span className="text-right text-sm">
              {formatNumber(operatingProfit)}
            </span>
          </div>
          <div className="grid grid-cols-2 py-1 font-bold">
            <span className="text-sm">Profit after Taxation</span>
            <span className="text-right text-sm">
              {formatNumber(operatingProfit)}
            </span>
          </div>
        </section>

        {/* Download Button */}
        <button
          className="mt-4 p-2 h-12  bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
          onClick={downloadProfitLoss}
        >
          <FontAwesomeIcon icon={faDownload}/>
        </button>
      </div>
    </div>
  );
};

export default ProfitLossView;
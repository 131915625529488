import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import ProfitLossView from "./ProfitLossView";
import CashFlowView from "./CashFlowView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

const KeyFindings = ({ costs, income, activeTab }) => {
  const analyzeData = () => {
    const findings = [];

    if (activeTab === "profitLoss") {
      // Calculate monthly totals
      const monthlyTotals = Array(12)
        .fill()
        .map((_, month) => {
          const monthlyIncome = income.reduce(
            (sum, inc) => sum + (inc.amounts[month] || 0),
            0
          );
          const monthlyCosts = costs.reduce(
            (sum, cost) => sum + (cost.amounts[month] || 0),
            0
          );
          return { income: monthlyIncome, costs: monthlyCosts };
        });

      // Check for months with losses
      monthlyTotals.forEach((month, index) => {
        if (month.costs > month.income) {
          findings.push(
            `You're forecasting a loss in Month ${index + 1} (£${Math.round(
              month.income - month.costs
            ).toLocaleString()})`
          );
        }
      });

      // Check overall profitability
      const totalIncome = monthlyTotals.reduce(
        (sum, month) => sum + month.income,
        0
      );
      const totalCosts = monthlyTotals.reduce(
        (sum, month) => sum + month.costs,
        0
      );
      if (totalCosts > totalIncome * 0.9) {
        findings.push(
          "Your costs are over 90% of your income - consider ways to reduce expenses"
        );
      }
    } else {
      // Cash Flow Analysis
      let runningBalance = 0;
      const monthlyFlows = Array(12)
        .fill()
        .map((_, month) => {
          const inflows = income.reduce((sum, inc) => {
            return sum + (inc.amounts[month] || 0);
          }, 0);

          const outflows = costs.reduce((sum, cost) => {
            return sum + (cost.amounts[month] || 0);
          }, 0);

          runningBalance += inflows - outflows;
          return { inflows, outflows, balance: runningBalance };
        });

      // Check for negative cash balance
      monthlyFlows.forEach((flow, index) => {
        if (flow.balance < 0) {
          findings.push(
            `Warning: Potential negative cash balance in Month ${
              index + 1
            } (£${Math.round(flow.balance).toLocaleString()})`
          );
        }
      });

      // Check for tight cash months
      monthlyFlows.forEach((flow, index) => {
        if (flow.outflows > flow.inflows * 0.9) {
          findings.push(
            `Cash flow is tight in Month ${
              index + 1
            } (outflows are ${Math.round(
              (flow.outflows / flow.inflows) * 100
            )}% of inflows)`
          );
        }
      });
    }

    return findings;
  };

  const findings = analyzeData();
  const [isExpanded, setIsExpanded] = useState(false);

  return findings.length > 0 ? (
    <div className="bg-white rounded-lg p-4 mb-8 text-left">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex justify-between items-center text-lg font-semibold hover:opacity-80 transition-opacity"
      >
        <span>Key Findings</span>
        <FontAwesomeIcon
          icon={isExpanded ? faChevronUp : faChevronDown}
          className="text-gray-600 text-sm"
        />
      </button>

      <div
        className={`transition-all duration-300 ease-in-out overflow-hidden ${
          isExpanded ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <ul className="space-y-2">
          {findings.map((finding, index) => (
            <li key={index} className="flex items-start space-x-2 text-sm">
              <span className="text-red-500">•</span>
              <span>{finding}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

function BankForecast({ documentId, onBack }) {
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("profitLoss");

  useEffect(() => {
    const fetchProjectData = async () => {
      if (!documentId) return;
      try {
        const projectRef = doc(db, "projects", documentId);
        const projectSnap = await getDoc(projectRef);
        if (projectSnap.exists()) {
          setProjectData(projectSnap.data());
        } else {
          console.error("No such document!");
        }
      } catch (e) {
        console.error("Error fetching document:", e);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [documentId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!projectData) {
    return <div>No data available</div>;
  }

  return (
    <div className="text-center mb-20 min-h-screen flex flex-col items-center relative">
      <button
        className="absolute top-4 left-4 text-white hover:text-gray-200 p-2 text-xl"
        onClick={onBack}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>

      <div className="w-full text-gray-800 px-4 sm:px-8 space-y-8">
        <div className="mt-4">
          <p className="text-white text-2xl mb-8">
            {projectData?.businessName}
          </p>

          <div className="flex text-sm justify-center mb-8">
            <div className="bg-white rounded-lg p-1 inline-flex">
              <button
                className={`px-4 py-2 rounded-lg transition-colors cursor-pointer ${
                  activeTab === "profitLoss"
                    ? "bg-blue-500 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
                onClick={() => setActiveTab("profitLoss")}
              >
                Profit & Loss
              </button>
              <button
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeTab === "cashFlow"
                    ? "bg-blue-500 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
                onClick={() => setActiveTab("cashFlow")}
              >
                Cash Flow
              </button>
            </div>
          </div>

          {projectData && (
            <div className="mt-8">
              <KeyFindings
                costs={projectData.costs || []}
                income={projectData.income || []}
                activeTab={activeTab}
              />
              {activeTab === "profitLoss" ? (
                <ProfitLossView
                  costs={projectData.costs || []}
                  income={projectData.income || []}
                />
              ) : (
                <CashFlowView
                  costs={projectData.costs || []}
                  income={projectData.income || []}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BankForecast;

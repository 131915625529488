const FAQ = () => {
  const faqs = [
    {
      question: "Is my data secure?",
      answer:
        "Yes, we use bank-level encryption and security measures to protect your data. Your information is stored securely and never shared with third parties.",
    },
    {
      question: "Can I export my forecasts?",
      answer:
        "Yes, you can export your forecasts to Excel or PDF format, making it easy to share with your team, accountant, or stakeholders.",
    },
    {
      question: "Do I need accounting knowledge?",
      answer:
        "No, our tool is designed to be user-friendly for business owners without financial expertise. We guide you through each step with clear explanations.",
    },
    {
      question: "How often is the data updated?",
      answer:
        "Your forecasts are updated in real-time as you input or modify your income and costs. You can see the impact of your changes immediately.",
    },
    {
      question: "Can I handle multiple currencies?",
      answer:
        "Currently, we support GBP for UK-based businesses. We're working on adding support for multiple currencies in future updates.",
    },
    {
      question: "What support is available?",
      answer:
        "We offer email support and detailed documentation to help you get the most out of the tool. Premium users also get access to priority support.",
    },
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">
          Frequently Asked Questions
        </h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
            >
              <h3 className="text-xl font-semibold mb-2 text-gray-800">
                {faq.question}
              </h3>
              <p className="text-gray-600">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;

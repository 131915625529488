import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faClock,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";

const Benefits = () => {
  const benefits = [
    {
      icon: faChartLine,
      title: "Better Decision Making",
      description:
        "Make informed business decisions with clear financial forecasting and real-time insights.",
    },
    {
      icon: faClock,
      title: "Save Time",
      description:
        "Automate your financial planning and spend less time on spreadsheets and calculations.",
    },
    {
      icon: faCalculator,
      title: "Financial Clarity",
      description:
        "Get a clear view of your cash flow, profit and loss, and overall financial health.",
    },
  ];

  return (
    <section className="py-16 mt-16 bg-gray-50">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl text-gray-800 font-bold text-center mb-12">
          Why Choose Forecast?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <div key={index} className="text-center p-6">
              <FontAwesomeIcon
                icon={benefit.icon}
                className="text-4xl text-blue-600 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
              <p className="text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;

import { useState } from "react";

const CashFlowView = ({ costs, income }) => {
  const [viewMode, setViewMode] = useState("monthly"); // 'monthly' or 'weekly'

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getWeekLabels = () => {
    // Generate 52 week labels
    return Array.from({ length: 52 }, (_, i) => {
      const weekNum = (i % 4) + 1; // Week number within month (1-4)
      const monthIndex = Math.floor(i / 4); // Month index (0-12)
      return monthIndex < 12 ? `${months[monthIndex]} W${weekNum}` : null;
    }).filter(Boolean); // Remove any null values
  };

  // Calculate cash flow for each period (week or month)
  const calculateFlows = () => {
    const periodsCount = viewMode === "monthly" ? 12 : 52;
    const periodData = Array(periodsCount)
      .fill()
      .map(() => ({
        inflows: 0,
        outflows: 0,
      }));

    // Helper to convert days to period index
    const getDueDate = (startPeriod, paymentDays) => {
      if (viewMode === "monthly") {
        return (startPeriod + Math.ceil(paymentDays / 30)) % 12;
      } else {
        return (startPeriod + Math.ceil(paymentDays / 7)) % 52;
      }
    };

    // Process costs and income
    const processTransactions = (transactions, isIncome) => {
      transactions.forEach((transaction) => {
        if (viewMode === "monthly") {
          transaction.amounts.forEach((amount, monthIndex) => {
            if (amount === 0) return;
            const dueDate = getDueDate(
              monthIndex,
              transaction.forecastPaymentDays || 0
            );
            const key = isIncome ? "inflows" : "outflows";
            periodData[dueDate][key] += amount;
          });
        } else {
          // For weekly view, split monthly amounts into weeks
          transaction.amounts.forEach((monthlyAmount, monthIndex) => {
            if (monthlyAmount === 0) return;
            const weeklyAmount = monthlyAmount / 4.33; // Approximate weekly amount
            const startWeek = monthIndex * 4;

            // Distribute the amount across weeks
            for (let week = 0; week < 4; week++) {
              const dueDate = getDueDate(
                startWeek + week,
                transaction.forecastPaymentDays || 0
              );
              const key = isIncome ? "inflows" : "outflows";
              periodData[dueDate][key] += weeklyAmount;
            }
          });
        }
      });
    };

    processTransactions(costs, false);
    processTransactions(income, true);

    return periodData;
  };

  const flows = calculateFlows();

  return (
    <div className="max-w-6xl mx-auto bg-white p-6 text-left shadow-lg rounded-lg overflow-x-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Cash Flow Forecast</h1>
        <div className="flex text-sm p-2 rounded-lg border items-center space-x-2">
          <button
            className={`px-4 py-2 rounded-lg ${
              viewMode === "monthly"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setViewMode("monthly")}
          >
            Monthly
          </button>
          <button
            className={`px-4 py-2 rounded-lg ${
              viewMode === "weekly"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setViewMode("weekly")}
          >
            Weekly
          </button>
        </div>
      </div>

      <table className="min-w-full text-sm">
        <thead>
          <tr className="bg-gray-50">
            <th className="px-4 py-2 text-left">Period</th>
            <th className="px-4 py-2 text-right">Cash Inflows</th>
            <th className="px-4 py-2 text-right">Cash Outflows</th>
            <th className="px-4 py-2 text-right">Net Cash Flow</th>
            <th className="px-4 py-2 text-right">Running Balance</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            let runningBalance = 0; // Reset running balance for each render
            return (viewMode === "monthly" ? months : getWeekLabels()).map(
              (label, index) => {
                const inflows = flows[index].inflows;
                const outflows = flows[index].outflows;
                const netFlow = inflows - outflows;
                runningBalance += netFlow;

                return (
                  <tr key={label} className="border-b">
                    <td className="px-4 py-2">{label}</td>
                    <td className="px-4 py-2 text-right text-green-600">
                      £{Math.round(inflows).toLocaleString()}
                    </td>
                    <td className="px-4 py-2 text-right text-red-600">
                      £{Math.round(outflows).toLocaleString()}
                    </td>
                    <td
                      className={`px-4 py-2 text-right ${
                        netFlow >= 0 ? "text-green-600" : "text-red-600"
                      }`}
                    >
                      £{Math.round(netFlow).toLocaleString()}
                    </td>
                    <td
                      className={`px-4 py-2 text-right font-medium ${
                        runningBalance >= 0 ? "text-green-600" : "text-red-600"
                      }`}
                    >
                      £{Math.round(runningBalance).toLocaleString()}
                    </td>
                  </tr>
                );
              }
            );
          })()}
        </tbody>
        <tfoot className="bg-gray-50 font-medium">
          <tr>
            <td className="px-4 py-2">Year End Total</td>
            <td className="px-4 py-2 text-right text-green-600">
              £
              {Math.round(
                flows.reduce((total, period) => total + period.inflows, 0)
              ).toLocaleString()}
            </td>
            <td className="px-4 py-2 text-right text-red-600">
              £
              {Math.round(
                flows.reduce((total, period) => total + period.outflows, 0)
              ).toLocaleString()}
            </td>
            <td className="px-4 py-2 text-right">
              £
              {Math.round(
                flows.reduce(
                  (total, period) => total + period.inflows - period.outflows,
                  0
                )
              ).toLocaleString()}
            </td>
            <td className="px-4 py-2"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default CashFlowView;

import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faChevronLeft,
  faChevronRight,
  faSave,
  faTrash,
  faGears,
  faInfoCircle,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";
import Costs from "./Costs";

function Income({ documentId, onBack }) {
  const [income, setIncome] = useState([
    {
      customerName: "",
      VATable: "Yes",
      amounts: Array(12).fill(0),
    },
  ]);
  const [globalPaymentTerms, setGlobalPaymentTerms] = useState(0);
  const [step, setStep] = useState(1);
  const [vatInputMode, setVatInputMode] = useState("excluding"); // 'excluding' or 'including'
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const handleAddIncome = () => {
    console.log("Adding a new income row");
    setIncome([
      ...income,
      {
        customerName: "",
        VATable: "Yes",
        amounts: Array(12).fill(0),
      },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const newIncome = [...income];
    newIncome[index][field] = value;
    setIncome(newIncome);
  };

  const handleAmountChange = (index, month, value) => {
    const newIncome = [...income];
    newIncome[index].amounts[month] = value === "" ? "" : parseFloat(value);
    setIncome(newIncome);
  };

  const handleDeleteIncome = (index) => {
    console.log("Deleting income row at index:", index);
    setIncome(income.filter((_, i) => i !== index));
  };

  const handleGlobalPaymentTermsChange = (value) => {
    setGlobalPaymentTerms(value === "" ? "" : parseInt(value));
  };

  const handleSave = async () => {
    if (!documentId) {
      console.error("Document ID is required to save income.");
      return;
    }
    try {
      console.log("Saving income for document ID:", documentId);
      const projectRef = doc(db, "projects", documentId);
      const incomeWithTerms = income.map((row) => ({
        ...row,
        forecastPaymentDays: globalPaymentTerms,
      }));
      await updateDoc(projectRef, { income: incomeWithTerms });
      console.log("Income saved successfully");
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  const handleNext = async () => {
    try {
      await handleSave();
      setStep(2);
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  return (
    <div className="w-full text-white text-gray-800 max-w-full px-4 sm:px-8">
      <style>
        {`
          /* For Chrome, Safari, Edge */
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* For Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
        `}
      </style>
      {step === 1 && (
        <>
          <p className="text-white self-start text-3xl md:text-5xl lg:text-5xl mt-8 mb-4">
            <FontAwesomeIcon icon={faReceipt} className="mr-4" />
            Expected Income
          </p>
          <p className="text-gray-200 self-start text-xl">
            Where do you expect revenue from, how much, and when?
          </p>

          <div className="flex gap-2 justify-end">
            <div className="flex items-center space-x-4">
              <button
                className="text-gray-700 bg-gray-200 border rounded-lg p-2 mb-2 text-sm"
                onClick={() => setShowAdvanced(!showAdvanced)}
              >
                <FontAwesomeIcon className="mr-2" icon={faGears} />
                {showAdvanced ? "Hide Advanced Settings" : "Advanced Settings"}
              </button>
            </div>
            <div className="flex items-center space-x-4">
              <button
                className="text-gray-700 bg-gray-200 border rounded-lg p-2 mb-2 text-sm"
                onClick={() => setShowInfo(!showInfo)}
              >
                <FontAwesomeIcon className="mr-2" icon={faInfoCircle} />
                {showInfo ? "Hide More Info" : "More Info"}
              </button>
            </div>
          </div>

          {showAdvanced && (
            <div className="flex flex-col gap-2 mb-4">
              <div className="flex items-center space-x-4">
                <label className="flex items-center space-x-2 text-white text-sm">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4"
                    checked={vatInputMode === "excluding"}
                    onChange={(e) =>
                      setVatInputMode(
                        e.target.checked ? "excluding" : "including"
                      )
                    }
                  />
                  <span>Exclude VAT from amounts</span>
                </label>
              </div>
              <div className="flex items-center space-x-4">
                <label className="flex items-center space-x-2 text-white text-sm">
                  <span>Payment Terms (days)</span>
                  <input
                    type="number"
                    className="form-input h-8 w-24 rounded-lg text-black px-2"
                    value={globalPaymentTerms}
                    onChange={(e) =>
                      handleGlobalPaymentTermsChange(e.target.value)
                    }
                    placeholder="0"
                    min="0"
                  />
                </label>
              </div>
            </div>
          )}

          {showInfo && (
            <div className="bg-blue-900 text-left p-4 rounded-lg mt-2">
              <p className="text-white text-sm mb-2">
                When do you expect to receive payment? Enter the number of days
                after the month ends (e.g. 30 means end of next month)
              </p>
              <p className="text-white text-sm">
                {vatInputMode === "excluding"
                  ? "Enter amounts excluding VAT. VAT will be calculated automatically for VATable items."
                  : "Enter amounts including VAT. Net amounts will be calculated automatically for VATable items."}
              </p>
            </div>
          )}

          <div className="overflow-x-auto bg-gray-50 rounded-lg text-black p-2 my-4">
            <table className="min-w-full table-auto">
              <thead>
                <tr>
                  <th className="px-1 py-2 text-sm font-semibold text-left">
                    Customer Name
                  </th>
                  <th className="px-1 py-2 text-xs font-semibold text-left">
                    VATable
                  </th>
                  {Array.from({ length: 12 }, (_, i) => (
                    <th
                      key={i}
                      className="px-1 py-2 text-xs font-light text-left"
                    >
                      Month {i + 1}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {income.map((income, index) => (
                  <tr key={index} className="">
                    <td className="px-1 py-2">
                      <input
                        className="p-2 text-black text-sm rounded-lg border border-gray-300 w-50 h-12"
                        type="text"
                        value={income.customerName}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "customerName",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td className="px-1 py-2">
                      <select
                        className="p-2 text-sm text-black rounded-lg border border-gray-300 w-fit h-12"
                        value={income.VATable}
                        onChange={(e) =>
                          handleInputChange(index, "VATable", e.target.value)
                        }
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </td>
                    {Array.from({ length: 12 }, (_, i) => (
                      <td key={i} className="px-1 py-2">
                        <input
                          className="p-2 text-black text-sm rounded-lg border border-gray-300 w-full h-12"
                          type="number"
                          value={income.amounts[i]}
                          onChange={(e) =>
                            handleAmountChange(index, i, e.target.value)
                          }
                        />
                      </td>
                    ))}
                    <td className="px-4 py-2">
                      <button
                        onClick={() => handleDeleteIncome(index)}
                        className="text-xs text-red-500"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col sm:flex-row justify-between mb-4 space-y-2 sm:space-y-0">
            <button
              className="bg-blue-500 text-white px-4 text-sm rounded-lg"
              onClick={handleAddIncome}
            >
              <FontAwesomeIcon className="mr-2" icon={faAdd} /> Customer
            </button>
            <div className="flex space-x-2">
              <button
                className="bg-gray-500 text-white p-2 text-sm rounded-lg hover:bg-gray-600 transition-colors w-10 h-10 flex items-center justify-center"
                onClick={onBack}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button
                className="bg-yellow-500 text-white p-2 text-sm rounded-lg hover:bg-green-600 transition-colors w-10 h-10 flex items-center justify-center"
                onClick={handleSave}
              >
                <FontAwesomeIcon icon={faSave} />
              </button>
              <button
                className="bg-green-500 text-white p-2 text-sm font-bold rounded-lg hover:bg-yellow-600 transition-colors w-fit h-10 flex items-center justify-center"
                onClick={handleNext}
              >
                {" "}
                Continue
                <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
              </button>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <Costs documentId={documentId} onBack={() => setStep(1)} />
      )}
    </div>
  );
}

export default Income;

<style>
  {`
    /* For Chrome, Safari, Edge */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* For Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  `}
</style>;

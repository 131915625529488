import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faCalculator,
  faClock,
  faCalendarAlt,
  faFileExport,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";

const Features = () => {
  const features = [
    {
      icon: faChartLine,
      title: "Cash Flow Forecasting",
      description: "Weekly and monthly views of your projected cash position",
    },
    {
      icon: faCalculator,
      title: "VAT Handling",
      description: "Automatic VAT calculations and forecasting",
    },
    {
      icon: faClock,
      title: "Payment Terms",
      description: "Factor in different payment terms for accurate projections",
    },
    {
      icon: faCalendarAlt,
      title: "Flexible Time Periods",
      description: "Switch between weekly and monthly views effortlessly",
    },
    {
      icon: faFileExport,
      title: "Export Options",
      description: "Download your forecasts in various formats",
    },
    {
      icon: faChartBar,
      title: "Visual Reports",
      description: "Clear graphs and charts for better understanding",
    },
  ];

  return (
    <section className="py-16 bg-white">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
          Everything You Need for Financial Planning
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="p-6 bg-gray-50 rounded-lg">
              <FontAwesomeIcon
                icon={feature.icon}
                className="text-4xl text-blue-600 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;

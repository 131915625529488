import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="max-w-6xl mx-auto px-4 flex flex-col items-center">
        <img src="/logo.svg" alt="Forecast Logo" className="w-12 h-12 mb-4" />
        <div className="text-center">
          <p className="mb-2">
            Made with{" "}
            <FontAwesomeIcon icon={faHeart} className="text-red-500" /> in the
            UK
          </p>
          <p className="text-sm text-gray-400">
            © {new Date().getFullYear()} Forecast. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faChevronLeft,
  faChevronRight,
  faSave,
  faAdd,
  faGears,
  faInfoCircle,
  faCircleInfo,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import BankForecast from "./BankForecast";

const SUBCATEGORIES = [
  "Direct Costs",
  "Overhead",
  "Accounts Receivable",
  "Current Asset",
  "Inventory",
  "Fixed Asset",
  "Accounts Payable",
  "Unpaid Expense Claims",
  "Current Liability",
  "VAT",
  "Historical",
  "Rounding",
  "Tracking",
  "Non-current Liability",
  "Equity",
  "Retained Earnings",
].sort();

function Costs({ documentId, onBack }) {
  const [costs, setCosts] = useState([
    {
      supplierName: "",
      VATable: "Yes",
      forecastPaymentDays: 0,
      category: "administrative",
      subcategory: "Overhead",
      paymentTerms: "immediate",
      amounts: Array(12).fill(0),
    },
  ]);
  const [step, setStep] = useState(1);
  const [excludeVAT, setExcludeVAT] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showSubcategoryColumn, setShowSubcategoryColumn] = useState(false);

  const handleAddCost = () => {
    console.log("Adding a new cost row");
    setCosts([
      ...costs,
      {
        supplierName: "",
        VATable: "Yes",
        forecastPaymentDays: 0,
        category: "administrative",
        subcategory: "Overhead",
        paymentTerms: "immediate",
        amounts: Array(12).fill(0),
      },
    ]);
  };

  const handleAddFixedMonthlyCost = () => {
    console.log("Adding a new fixed monthly cost row");
    setCosts([
      ...costs,
      {
        supplierName: "",
        VATable: "Yes",
        forecastPaymentDays: 0,
        category: "administrative",
        subcategory: "Overhead",
        paymentTerms: "immediate",
        isFixedMonthly: true,
        fixedAmount: 0,
        amounts: Array(12).fill(0),
      },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const newCosts = [...costs];
    newCosts[index][field] = value;
    setCosts(newCosts);
  };

  const handleAmountChange = (index, month, value) => {
    const newCosts = [...costs];
    const cost = newCosts[index];

    if (cost.isFixedMonthly) {
      cost.fixedAmount = value === "" ? "" : parseFloat(value);
      cost.amounts = Array(12).fill(cost.fixedAmount);
    } else {
      cost.amounts[month] = value === "" ? "" : parseFloat(value);
    }

    setCosts(newCosts);
  };

  const handleDeleteCost = (index) => {
    console.log("Deleting cost row at index:", index);
    setCosts(costs.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    if (!documentId) {
      console.error("Document ID is required to save costs.");
      return;
    }
    try {
      console.log("Saving costs for document ID:", documentId);
      const projectRef = doc(db, "projects", documentId);
      await updateDoc(projectRef, { costs });
      console.log("Costs saved successfully");
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  const handleNext = async () => {
    try {
      await handleSave();
      setStep(2); // Move to the next step
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  return (
    <div className="w-full text-white max-w-full">
      <style>
        {`
          /* For Chrome, Safari, Edge */
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* For Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
        `}
      </style>

      {step === 1 && (
        <>
          <p className="text-white self-start text-3xl md:text-5xl lg:text-5xl mt-8 mb-4">
            <FontAwesomeIcon icon={faMoneyBill} className="mr-4" />
            Expected Costs
          </p>
          <p className="text-gray-200 self-start text-xl">
            How much do you expect to spend, where, and when?
          </p>

          <div className="flex gap-2 justify-end">
            <div className="flex items-center space-x-4">
              <button
                className="text-gray-800 bg-gray-200 border rounded-lg p-2 mb-2 text-sm "
                onClick={() => setShowAdvanced(!showAdvanced)}
              >
                <FontAwesomeIcon className="mr-2" icon={faGears} />
                {showAdvanced ? "Hide Advanced Settings" : "Advanced Settings"}
              </button>
            </div>
            <div className="flex items-center space-x-4">
              <button
                className="text-gray-800 bg-gray-200 border rounded-lg p-2 mb-2 text-sm "
                onClick={() => setShowInfo(!showInfo)}
              >
                <FontAwesomeIcon className="mr-2" icon={faInfoCircle} />
                {showInfo ? "Hide More Info" : "More Info"}
              </button>
            </div>
          </div>

          {showAdvanced && (
            <div className="flex flex-col gap-2 mb-4">
              <div className="flex items-center space-x-4">
                <label className="flex items-center space-x-2 text-white text-sm">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4"
                    checked={excludeVAT}
                    onChange={(e) => setExcludeVAT(e.target.checked)}
                  />
                  <span>Exclude VAT from amounts</span>
                </label>
              </div>
              <div className="flex items-center space-x-4">
                <label className="flex items-center space-x-2 text-white text-sm">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4"
                    checked={showSubcategoryColumn}
                    onChange={(e) => setShowSubcategoryColumn(e.target.checked)}
                  />
                  <span>Include subcategory column</span>
                </label>
              </div>
            </div>
          )}

          {showInfo && (
            <div className="bg-blue-900 text-left p-4 rounded-lg mt-2">
              <p className="text-white text-sm mb-2">
                When do you expect to make payment? Enter the number of days
                after the month ends (e.g. 30 means end of next month)
              </p>
              <p className="text-white text-sm">
                {excludeVAT
                  ? "Enter amounts excluding VAT. VAT will be calculated automatically for VATable items."
                  : "Enter amounts including VAT. Net amounts will be calculated automatically for VATable items."}
              </p>
            </div>
          )}

          <div className="overflow-x-auto bg-gray-50 rounded-lg text-black p-2 my-4">
            <table className="min-w-full table-auto">
              <thead>
                <tr>
                  <th className="px-1 py-2 text-sm font-semibold text-left">
                    Supplier Name
                  </th>
                  <th className="px-1 py-2 text-xs font-semibold text-left">
                    Category
                  </th>
                  {showSubcategoryColumn && (
                    <th className="px-1 py-2 text-xs font-semibold text-left">
                      Subcategory
                    </th>
                  )}
                  <th className="px-1 py-2 text-xs font-semibold text-left">
                    VATable
                  </th>
                  <th className="px-1 py-2 text-xs font-semibold text-left">
                    <div className="flex items-center gap-1 group relative">
                      Terms
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="text-gray-600 hover:text-gray-600 cursor-help h-3 w-3"
                      />
                      <div className="absolute left-0 top-6 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 w-48 z-10">
                        When do you expect to make payment?
                        <div className="absolute -top-1 left-[10%] w-2 h-2 bg-gray-800 rotate-45" />
                      </div>
                    </div>
                  </th>
                  {Array.from({ length: 12 }, (_, i) => (
                    <th
                      key={i}
                      className="px-1 py-2 text-xs font-light text-left"
                    >
                      Month {i + 1}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {costs.map((cost, index) => (
                  <tr key={index} className="">
                    <td className="px-1 py-2">
                      <input
                        className="p-2 text-sm text-black rounded-lg border border-gray-300 w-50 h-12"
                        type="text"
                        value={cost.supplierName}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "supplierName",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td className="px-1 py-2">
                      <select
                        className="p-2 text-sm text-black rounded-lg border border-gray-300 w-fit h-12"
                        value={cost.category}
                        onChange={(e) =>
                          handleInputChange(index, "category", e.target.value)
                        }
                      >
                        <option value="sales">Sales</option>
                        <option value="otherRevenue">Other Revenue</option>
                        <option value="interestIncome">Interest Income</option>
                        <option value="costOfGoodsSold">
                          Cost of Goods Sold
                        </option>
                        <option value="directWages">Direct Wages</option>
                        <option value="directExpenses">Direct Expenses</option>
                        <option value="advertisingMarketing">
                          Advertising & Marketing
                        </option>
                        <option value="auditAccountancy">
                          Audit & Accountancy fees
                        </option>
                        <option value="bankFees">Bank Fees</option>
                        <option value="cleaning">Cleaning</option>
                        <option value="consulting">Consulting</option>
                        <option value="depreciation">
                          Depreciation Expense
                        </option>
                        <option value="charitableDonations">
                          Charitable & Political Donations
                        </option>
                        <option value="entertainment100">
                          Entertainment-100% business
                        </option>
                        <option value="entertainment0">
                          Entertainment - 0%
                        </option>
                        <option value="postageFreight">
                          Postage, Freight & Courier
                        </option>
                        <option value="generalExpenses">
                          General Expenses
                        </option>
                      </select>
                    </td>
                    {showSubcategoryColumn && (
                      <td className="px-1 py-2">
                        <select
                          className="p-2 text-sm rounded-lg border text-black border-gray-300 w-20 h-12"
                          value={cost.subcategory}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "subcategory",
                              e.target.value
                            )
                          }
                        >
                          {SUBCATEGORIES.map((subcat) => (
                            <option key={subcat} value={subcat}>
                              {subcat}
                            </option>
                          ))}
                        </select>
                      </td>
                    )}
                    <td className="px-1 py-2">
                      <select
                        className="p-2 text-sm text-black  rounded-lg border border-gray-300 w-fit h-12"
                        value={cost.VATable}
                        onChange={(e) =>
                          handleInputChange(index, "VATable", e.target.value)
                        }
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </td>
                    <td className="px-1 py-2">
                      <input
                        className="p-2 text-sm text-black rounded-lg border border-gray-300 w-full h-12"
                        type="number"
                        placeholder="e.g. 30 for end of next month"
                        value={cost.forecastPaymentDays}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "forecastPaymentDays",
                            e.target.value ? parseInt(e.target.value) : ""
                          )
                        }
                      />
                    </td>
                    {cost.isFixedMonthly ? (
                      <td colSpan={12} className="px-1 py-2">
                        <div className="flex items-center space-x-2">
                          <input
                            className="p-2 text-sm text-black rounded-lg border border-gray-300 w-full h-12"
                            type="number"
                            placeholder="Enter fixed monthly amount"
                            value={cost.fixedAmount || ""}
                            onChange={(e) =>
                              handleAmountChange(index, 0, e.target.value)
                            }
                          />
                        </div>
                      </td>
                    ) : (
                      Array.from({ length: 12 }, (_, i) => (
                        <td key={i} className="px-1 py-2">
                          <input
                            className="p-2 text-sm text-black rounded-lg border border-gray-300 w-full h-12"
                            type="number"
                            value={cost.amounts[i]}
                            onChange={(e) =>
                              handleAmountChange(index, i, e.target.value)
                            }
                          />
                        </td>
                      ))
                    )}
                    <td className="px-4 py-2">
                      <button
                        onClick={() => handleDeleteCost(index)}
                        className=" text-xs text-red-500"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col sm:flex-row justify-between mb-4 space-y-2 sm:space-y-0">
            <div className="flex space-x-2">
              <button
                className="bg-blue-500 text-white px-4 text-sm rounded-lg hover:bg-blue-600 transition-colors flex items-center space-x-2"
                onClick={handleAddCost}
                title="Add Variable Cost"
              >
                <FontAwesomeIcon icon={faAdd} />
                <span>Monthly Variable Cost</span>
              </button>
              <button
                className="bg-blue-600 text-white px-4 text-sm rounded-lg hover:bg-blue-600 transition-colors flex items-center space-x-2"
                onClick={handleAddFixedMonthlyCost}
                title="Add Fixed Monthly Cost"
              >
                <FontAwesomeIcon icon={faAdd} />
                <span>Fixed Monthly Cost</span>
              </button>
            </div>
            <div className="flex space-x-2">
              <button
                className="bg-gray-500 text-white p-2 text-sm rounded-lg hover:bg-gray-600 transition-colors w-10 h-10 flex items-center justify-center"
                onClick={onBack}
                title="Back"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button
                className="bg-yellow-500 text-white p-2 text-sm rounded-lg hover:bg-green-600 transition-colors w-10 h-10 flex items-center justify-center"
                onClick={handleSave}
                title="Save"
              >
                <FontAwesomeIcon icon={faSave} />
              </button>
              <button
                className="bg-green-500 text-white p-2 text-sm rounded-lg hover:bg-yellow-600 transition-colors w-fit font-bold h-10 flex items-center justify-center"
                onClick={handleNext}
                title="Next"
              >
                Continue
                <FontAwesomeIcon className="ml-2" icon={faChevronRight} />{" "}
              </button>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <BankForecast documentId={documentId} onBack={() => setStep(1)} />
      )}
    </div>
  );
}

export default Costs;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faMoneyBillWave,
  faReceipt,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";

const HowItWorks = () => {
  const steps = [
    {
      icon: faBuilding,
      title: "Enter Business Details",
      description:
        "Start by entering your business name and basic information.",
    },
    {
      icon: faMoneyBillWave,
      title: "Add Income",
      description: "Input your expected income sources and payment terms.",
    },
    {
      icon: faReceipt,
      title: "Add Costs",
      description: "Enter your expected costs and payment schedules.",
    },
    {
      icon: faChartPie,
      title: "View Forecasts",
      description:
        "Get instant access to cash flow and profit & loss forecasts.",
    },
  ];

  return (
    <section className="py-16">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="text-center p-6">
              <div className="relative">
                <FontAwesomeIcon
                  icon={step.icon}
                  className="text-4xl text-white mb-4"
                />
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-1/2 -right-4 w-8 h-0.5 bg-gray-300"></div>
                )}
              </div>
              <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
              <p className="text-gray-200">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
